@font-face {
  font-family: "ProximaNova-Light";
  src: url("./fonts/ProximaNova-Light-webfont.eot"),
    url("./fonts/ProximaNova-Light-webfont.eot?iefix")
      format("embedded-opentype"),
    url("./fonts/ProximaNova-Light-webfont.woff") format("woff"),
    url("./fonts/ProximaNova-Light-webfont.ttf") format("truetype"),
    url("./fonts/ProximaNova-Light-webfont.svg#gothamlight") format("svg");
}

@font-face {
  font-family: "Gibson";
  src: url("./fonts/Gibson-Bold-webfont.eot"),
    url("./fonts/Gibson-Bold-webfont.eot?iefix") format("embedded-opentype"),
    url("./fonts/Gibson-Bold-webfont.woff") format("woff"),
    url("./fonts/Gibson-Bold-webfont.ttf") format("truetype"),
    url("./fonts/Gibson-Bold-webfont.svg#gothamlight") format("svg");
}
