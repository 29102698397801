@import url(https://cdnjs.cloudflare.com/ajax/libs/uikit/3.1.2/css/uikit.min.css);
@font-face {
  font-family: "ProximaNova-Light";
  src: url(/static/media/ProximaNova-Light-webfont.ff9c4a8d.eot),
    url(/static/media/ProximaNova-Light-webfont.ff9c4a8d.eot)
      format("embedded-opentype"),
    url(/static/media/ProximaNova-Light-webfont.04d5b9ed.woff) format("woff"),
    url(/static/media/ProximaNova-Light-webfont.86c7892e.ttf) format("truetype"),
    url(/static/media/ProximaNova-Light-webfont.da3d0fb7.svg#gothamlight) format("svg");
}

@font-face {
  font-family: "Gibson";
  src: url(/static/media/Gibson-Bold-webfont.daba247d.eot),
    url(/static/media/Gibson-Bold-webfont.daba247d.eot) format("embedded-opentype"),
    url(/static/media/Gibson-Bold-webfont.c6132b6e.woff) format("woff"),
    url(/static/media/Gibson-Bold-webfont.916bb484.ttf) format("truetype"),
    url(/static/media/Gibson-Bold-webfont.219322e5.svg#gothamlight) format("svg");
}

@charset "UTF-8";
html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  font-family: ProximaNova-Light, "Helvetica Nueue", Helvetica, Arial, Roboto;
  font-size: 18px;
  background: #bf263c;
}

.days-container em {
  color: #85061e;
}

.uk-tab > * > a {
  color: #fff;
}

.uk-tab > * > a:hover {
  color: rgba(0, 0, 0, 0.8);
}

h1,
h3,
body {
  font-family: "ProximaNova-Light" !important;
}

.logo {
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.uk-tab li.uk-active {
  font-weight: bold;
}

.uk-tab li.uk-active a {
  color: #fff;
}

.uk-tab > .uk-active > a {
  color: #333;
  border-color: rgba(0, 0, 0, 0) !important;
}

.uk-tab > li.uk-active {
  border: solid #fff;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  background: rgba(255, 255, 255, 0.6);
}

.day-list li {
  padding: 0.3em;
  color: rgba(0, 0, 0, 0.8);
}

.day-list li:hover {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.8);
}

.calendar-header {
  background: #dddddd !important;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 1px !important;
  padding: 0.35em 0.5em;
  cursor: pointer;
}

.days-container {
  border: solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  background: rgba(255, 255, 255, 0.6);
}

.days-container.closed {
  border-top: solid #fff;
}

.uk-tab::before {
  border-bottom: 2px solid #fff;
}

.max-tablet-width {
  max-width: 720px;
  margin: auto;
}

.booking-rows-header {
  margin-top: 1em;
}

.uk-row {
  margin: 2px 0;
}

.filter-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.white-text {
  color: #fff;
}

dt {
  font-family: "Gibson";
}

.react-geocoder input {
  height: 30px;
  border: solid 1px green;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px 6px;
}

.react-geocoder-results {
  font-size: 0.9em;
  border: solid 1px #ddd;
}

.react-geocoder-results div {
  padding: 3px 6px;
}

.react-geocoder-results div:hover {
  background: #ededed;
  cursor: pointer;
}

.booking-row {
  display: grid;
  grid-template-columns: 500px 160px 1fr 1fr 1fr;
  grid-column-gap: 1em;
  background: #fafafa;
  padding: 4px;
  font-size: 1em;
  margin-top: 2px;
  border-radius: 2px;
  color: #000;
}

.booking-row a {
  color: rgba(0, 0, 0, 0.8);
}

.booking-row:nth-child(odd) {
  background: rgba(176, 172, 172, 0.73);
}

.booking-row:hover {
  background: #888;
  color: #fff;
}

.booking-row:hover a {
  color: #fff;
}

.booking-row button:hover {
  color: #000 !important;
}

.booking-row.header {
  background: #888;
  color: #fff;
}

.pac-container {
  z-index: 99999;
}

.tiny-text {
  font-size: 9px;
}

.suggestions {
  position: relative;
  display: grid;
  grid-row-gap: 0.5em;
  padding: 1em;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0 0 8px 8px;
}

.upcoming {
  background: #ffeb3b;
  margin: 1em 0;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  border-color: rgba(0, 0, 0, 0) !important;
}

.suggestions div {
  padding: 1em;
  color: #000;
  cursor: pointer;
}
.suggestions div:hover {
  background: rgba(255, 255, 255, 0.6);
}

.qm-frame-container {
  margin: 0;
}

.qm-selected {
  color: #bf263c;
  text-shadow: hoff voff blur #fff;
  line-height: 20px;
  background: #fff !important;
  font-weight: bold;
}

.qm-selected > span:after {
  content: "\2713";
  font-size: 20px;
  line-height: 1.4;
  color: #bf263c;
  margin-left: 4px;
}

.not-selectable {
  pointer-events: none;
}

dt {
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  margin-top: 1em;
}

.upcoming {
  background: rgba(255, 255, 255, 0.6);
  margin: 1em 0;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #b31028;
}

.uk-input {
  border-radius: 4px;
}

.marker {
  width: 0;
  height: 0;
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #4d2d73;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg);
}

.temporary-marker span {
  background: rgba(204, 0, 0, 0.8);
}

.big-number {
  color: #bf263c;
  font-weight: bold;
  font-family: Gibson;
}

.uk-tab {
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.muted {
  font-style: italic;
  color: #3f51b5;
  font-family: Times;
}

.uk-text-muted {
  cursor: none;
  pointer-events: none;
}

label {
  cursor: pointer !important;
}

.capitalize {
  text-transform: capitalize;
}

.thanks-logo {
  max-width: 180px;
}

h1 {
  font-weight: bolder;
}

dd {
  margin-left: 0;
}
dd ul {
  margin: 0;
}
dd ul li {
  cursor: pointer;
  line-height: 1.4;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0 !important;
}
dd ul li .time-line {
  text-transform: capitalize;
}

.joinaq-input {
  background-image: url(//joinaq.com/assets/icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  padding-right: 4px;
  padding: 2px 4px 2px 12px;
  box-sizing: border-box;
}

.uk-button {
  border-radius: 8000px;
}

.uk-alert {
  margin: 0;
}

.max-table-width {
  width: 800px !important;
}

.button-row {
  text-align: right;
}
.phone-number-row
{
  text-align: left;
}

@media (max-width: 540px) {
  .header {
    display: none;
  }

  .booking-row {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    padding: 0.5em;
  }
  .button-row
  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .phone-number-row
  {
    text-align: right;
  }

  .filter-row {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 1em;
  }
  h3 {
    ont-size: 1.2rem !important;
  }
}
/*# sourceMappingURL=index.css.map */

/*# sourceMappingURL=index.css.map */

